import { NextApiRequest, NextApiResponse } from 'next'
import { Session, GetAccessToken, SessionCache, fromJson } from '../session'

//import { getProtocol } from './util'

interface ProfileOptions {
  refetch?: boolean
}

export const handler =
  (sessionCache: SessionCache, getAccessToken: GetAccessToken) =>
  async (req: NextApiRequest, res: NextApiResponse, options?: ProfileOptions) => {
    if (req.method === 'GET') {
      res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate')
      res.setHeader('Access-Control-Allow-Headers', '*')

      //const protocol = getProtocol(req)

      try {
        const isAuth = await sessionCache.isAuthenticated(req, res)
        if (!isAuth) {
          res.status(200).json(null)
          return
        }

        const session = (await sessionCache.get(req, res)) as Session
        res.setHeader('Cache-Control', 'no-store')

        if (options?.refetch) {
          const { accessToken } = await getAccessToken(req, res)
          if (!accessToken) {
            throw new Error('No access token available to refetch the profile')
          }

          //TODO: get the user info for real
          // const client = await getClient()
          const userInfo = {} // await client.userinfo(accessToken)

          const newSession = fromJson({
            ...session,
            user: {
              ...session.user,
              ...userInfo,
            },
          }) as Session

          await sessionCache.set(req, res, newSession)

          res.json(newSession.user)
          return
        }

        res.json(session.user)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Error with authorization redirect', error)
        res.redirect(`${process.env.OAUTH2_BASE_URL_THEATRICAL}`)
      }
    } else {
      res.status(405)
      res.end()
    }
  }

export default handler
