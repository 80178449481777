import { AUTH_SESSION_COOKIE } from '@/constants/cookies'
import { Durations } from '@/constants/durations'

export default class ClientConfig {
  secret: string
  authorizationUri: string
  tokenUri: string
  logoutUri: string
  scope: string
  audience: string
  clientId: string
  clientSecret: string
  session: {
    name: string
    rollingDuration: number
    absoluteDuration: number
    cookie: {
      secure: boolean
      domain?: string
      sameSite: 'lax' | 'strict' | 'none'
      transient: boolean
      httpOnly: boolean
    }
  }

  constructor() {
    this.secret = process.env.OAUTH2_SECRET as string
    this.authorizationUri = `${process.env.OAUTH2_ISSUER_BASE_URL}/authorize`
    this.tokenUri = `${process.env.OAUTH2_ISSUER_BASE_URL}/oauth/token`
    this.logoutUri = `${process.env.OAUTH2_ISSUER_BASE_URL}/logout`
    this.scope = process.env.OAUTH2_SCOPE as string
    this.audience = process.env.OAUTH2_AUDIENCE as string
    this.clientId = process.env.OAUTH2_CLIENT_ID as string
    this.clientSecret = process.env.OAUTH2_CLIENT_SECRET as string
    this.session = {
      name: AUTH_SESSION_COOKIE,
      rollingDuration: Durations.ONE_DAY_IN_SECONDS,
      absoluteDuration: Durations.ONE_WEEK_IN_SECONDS,
      cookie: {
        secure: process.env.OAUTH2_BASE_URL_THEATRICAL?.indexOf('https://') === 0,
        sameSite: 'lax',
        transient: false,
        httpOnly: true,
      },
    }
  }
}
